<template>
	<v-container fluid fill-height>
    <template v-if="isAdmin">
		<v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <base-heading title="Inicio"/>
      </v-col>
    </v-row>
    <v-row> 
      <v-col cols="12" lg="4">
        <base-material-chart-card
          :data="monthlyPurchasesChart.data"
          :options="monthlyPurchasesChart.options"
          color="grey lighten-3"
          hover-reveal
          type="Bar"
          :loading="monthlyPurchasesLoading"
          :loaded="monthlyPurchasesLoaded"
        >
          <template v-slot:reveal-actions>
            <base-icon-btn color="info" @click="getMonthlyPurchases()" icon="mdi-refresh" tooltip="Actualizar"></base-icon-btn>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Compras Realizadas
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Gastos del último año
          </p>

          <base-text-field v-model="monthlyPurchasesBranchOffice" label="Sucursal" readonly></base-text-field>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">{{editedLastUpdate.monthlyPurchases}}</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col cols="12" lg="4">
        <base-material-chart-card
          :data="dailySalesChart.data"
          :options="dailySalesChart.options"
          color="grey lighten-3"
          hover-reveal
          type="Line"
          :loading="dailySalesLoading"
          :loaded="dailySalesLoaded"
        >
          <template v-slot:reveal-actions>
            <base-icon-btn color="info" @click="getDailySales()" icon="mdi-refresh" tooltip="Actualizar"></base-icon-btn>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Ventas Diarias
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            <v-icon
              color="green"
              small
            >
              mdi-arrow-up
            </v-icon>
            Rendimiento de la semana
          </p>

          <base-select v-model="dailySalesBranchOfficeId" :items="branchOffices" label="Sucursal" @change="getDailySales()"></base-select>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">{{editedLastUpdate.dailySales}}</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col cols="12" lg="4">
        <base-material-chart-card
          :data="monthlySalesChart.data"
          :options="monthlySalesChart.options"
          hover-reveal
          color="grey lighten-3"
          type="Bar"
          :loading="monthlySalesLoading"
          :loaded="monthlySalesLoaded"
        >
          <template v-slot:reveal-actions>
            <base-icon-btn color="info" @click="getMonthlySales()" icon="mdi-refresh" tooltip="Actualizar"></base-icon-btn>
          </template>

          <h3 class="card-title font-weight-light mt-2 ml-2">
            Ventas Generales
          </h3>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Rendimiento del último año
          </p>

          <base-select v-model="monthlySalesBranchOfficeId" :items="branchOffices" label="Sucursal" @change="getMonthlySales()"></base-select>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">{{editedLastUpdate.monthlySales}}</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col cols="12">
        <v-divider color="success"></v-divider>
      </v-col>

      <v-col cols="12" align="center">
        <base-btn color="secondary" @click="getControlPanel()" icon="mdi-reload" btnText="Actualizar Panel de Control"></base-btn>
      </v-col>
      
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <!-- color="info" -->
        <base-material-stats-card
          color="primary"
          icon="mdi-home-city"
          title="Sucursales"
          :value="`${editedControlPanel.currentBranchOffices}/${editedControlPanel.branchOfficeLimit}`"
          sub-icon="mdi-clock"
          :sub-text="editedLastUpdate.branchOfficeLimit"
          :loading="branchOfficeLoading"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <!-- color="yellow darken-2" -->
        <base-material-stats-card
          color="secondary"
          icon="mdi-account-group"
          title="Usuarios"
          :value="`${editedControlPanel.currentUsers}/${editedControlPanel.userLimit}`"
          sub-icon="mdi-clock"
          :sub-text="editedLastUpdate.userLimit"
          :loading="userLoading"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <!-- color="green darken-1" -->
        <base-material-stats-card
          color="info"
          icon="mdi-cart"
          title="Productos"
          :value="`${editedControlPanel.currentProducts}/${editedControlPanel.productLimit}`"
          sub-icon="mdi-clock"
          :sub-text="editedLastUpdate.productLimit"
          :loading="productLoading"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <!-- color="info" -->
        <base-material-stats-card
          color="yellow darken-2"
          icon="mdi-account-multiple-check"
          title="Clientes"
          :value="`${editedControlPanel.currentCustomers}`"
          sub-icon="mdi-clock"
          :sub-text="editedLastUpdate.customers"
          :loading="customerLoading"
        />
      </v-col>
      
      <v-col cols="12">
        <v-divider color="success"></v-divider>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          color="success"
          class="px-5 py-3"
          :loading="topProductsLoading"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              <base-icon-btn color="white" large :min-elevation="0" @click="getMainProductsSold()" icon="mdi-refresh" tooltip="Actualizar"></base-icon-btn>
              TOP 10 - Productos más vendidos
            </div>
          </template>
          <v-card-text>
            <base-data-table 
              :headers="productHeaders" 
              :items="products"
              hideReloadButton
              hideNewItemButton
              showEnabledField
              hideHeader
              hideFooter
							rows="10"
              elevation="0"
            >
            </base-data-table>
          </v-card-text>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">{{editedLastUpdate.topProducts}}</span>
          </template>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card 
          color="info" 
          class="px-5 py-3"
          :loading="topCustomersLoading"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              <base-icon-btn color="white" large :min-elevation="0" @click="getUsualCustomers()" icon="mdi-refresh" tooltip="Actualizar"></base-icon-btn>
              TOP 10 - Clientes Recurrentes
            </div>
          </template>

          <v-card-text>
            <base-data-table 
              :headers="customerHeaders" 
              :items="customers"
              hideReloadButton
              hideNewItemButton
              showEnabledField
              hideHeader
              hideFooter
							rows="10"
              elevation="0"
            >
            </base-data-table>
          </v-card-text>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">{{editedLastUpdate.topCustomers}}</span>
          </template>
        </base-material-card>
      </v-col>
      
		</v-row>
    </template>

    <v-row v-else justify="center" align="center">
      <v-col cols="12" md="6" align="center" >
        <h1 class="font-weight-bold grey--text pb-4">¡Bienvenido al sistema!</h1>
        <v-img
            class="mb-6"
            contain
            height="100"
            :src="require('@/assets/logo_gavcom.png')"
          ></v-img>
        <h2 class="font-weight-bold grey--text pb-4">Para empezar, selecciona una de las opciones que se encuentran habilitadas para tí en el menu principal.</h2>
        <v-icon v-if="$vuetify.breakpoint.mdAndUp" size="64">mdi-arrow-left-thick</v-icon>
        <v-icon v-else size="64">mdi-arrow-top-left-thick</v-icon>
      </v-col>
    </v-row>

	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import { mapGetters } from 'vuex'

export default {
	mixins: [MainExternalData],
	
	data() {
		return {
      branchOffices:[],
      editedControlPanel:{
        branchOfficeLimit: 0,
        currentBranchOffices : 0,
        userLimit: 0,
        currentUsers : 0,
        productLimit: 0,
        currentProducts : 0,
        currentCustomers: 0,
      },
      defaultControlPanel:{
        branchOfficeLimit: 0,
        currentBranchOffices : 0,
        userLimit: 0,
        currentUsers : 0,
        productLimit: 0,
        currentProducts : 0,
        currentCustomers: 0,
      },
      branchOfficeLoading: false,
      userLoading: false,
      productLoading: false,
      customerLoading: false,

      editedDates: {
        monthlyPurchases: '',
        dailySales: '',
        monthlySales: '',

        branchOfficeLimit: '',
        userLimit: '',
        productLimit: '',
        customers: '',

        topProducts: '',
        topCustomers: '',
      },
      editedLastUpdate: {
        monthlyPurchases: '',
        dailySales: '',
        monthlySales: '',

        branchOfficeLimit: '',
        userLimit: '',
        productLimit: '',
        customers: '',

        topProducts: '',
        topCustomers: '',
      },


      monthlyPurchasesLoading: false,
      monthlyPurchasesLoaded:false,
      highMonthlyPurchase: 0,
      monthlyPurchasesBranchOffice:'',
			monthlyPurchasesLabels:[],
      monthlyPurchasesValues:[],
      defaultMonthlyPurchasesLabels:['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      defaultMonthlyPurchasesValues:[],
      monthlyPurchasesChart: {
        data: {
          labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          datasets: [
            {
              label: 'Compras Realizadas',
              backgroundColor: '#E53935',
              pointBackgroundColor: 'white',
              borderWidth: 1,
              pointBorderColor: '#249EBF',
              data: []
              // data: [60, 40, 20, 50, 90, 10, 20, 40, 50, 70, 90, 100]
            }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        }
      },

      dailySalesLoading: false,
      dailySalesLoaded:false,
      highDailySale: 0,
      dailySalesBranchOfficeId:'',
			dailySalesLabels:[],
      dailySalesValues:[],
      defaultDailySalesLabels:['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
      defaultDailySalesValues:[],
      dailySalesChart: {
        data: {
          labels: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
          datasets: [
            {
              label: 'Ventas Diarias',
              backgroundColor: '#FBC02D',
              pointBackgroundColor: 'white',
              borderWidth: 1,
              pointBorderColor: '#249EBF',
              data: [],
              // data: [60, 40, 20, 50, 90, 10, 20]
            }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        }
      },

      monthlySalesLoading: false,
      monthlySalesLoaded:false,
      highMonthlySale: 0,
      monthlySalesBranchOfficeId:'',
			monthlySalesLabels:[],
      monthlySalesValues:[],
      defaultMonthlySalesLabels:['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      defaultMonthlySalesValues:[],
      monthlySalesChart: {
        data: {
          labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          datasets: [
            {
              label: 'Ventas Generales',
              backgroundColor: '#4CAF50',
              pointBackgroundColor: 'white',
              borderWidth: 1,
              pointBorderColor: '#249EBF',
              data: []
              // data: [60, 40, 20, 50, 90, 10, 20, 40, 50, 70, 90, 100]
            }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        }
      },



			productHeaders: [
				{ text: "Nro", value: "order", sortable: false },
        { text: "Código", value: "customCode", sortable: false },
				{ text: "Producto", value: "visualName", sortable: false },
				{ text: "Ventas Realizadas", value: "totalSales", sortable: false },
			],
      products: [],
      topProductsLoading: false,

			customerHeaders: [
				{ text: "Nro", value: "order", sortable: false },
        { text: "Cliente", value: "fullName", sortable: false },
				{ text: "Compras Realizadas", value: "quantity", sortable: false },
			],
      customers: [],
      topCustomersLoading: false,
    }
  },
  
  computed: {
    ...mapGetters(['isAdmin']),
  },

	async created() {
    if (this.isAdmin) {
      await this.listOfBranchOffices();
      this.dailySalesBranchOfficeId = this.companyData.branchOfficeId
      this.monthlySalesBranchOfficeId= this.companyData.branchOfficeId
      this.getControlPanel()
      this.getMonthlyPurchases()
      this.getDailySales()
      this.getMonthlySales()
      this.getMainProductsSold()
      this.getUsualCustomers()
    }
  },

  mounted() {
    const intervalid1 = setInterval(() => {
      this.editedLastUpdate.monthlyPurchases = this.elapsedTime(this.editedDates.monthlyPurchases)
      this.editedLastUpdate.dailySales = this.elapsedTime(this.editedDates.dailySales)
      this.editedLastUpdate.monthlySales = this.elapsedTime(this.editedDates.monthlySales)
      
      this.editedLastUpdate.branchOfficeLimit = this.elapsedTime(this.editedDates.branchOfficeLimit)
      this.editedLastUpdate.userLimit = this.elapsedTime(this.editedDates.userLimit)
      this.editedLastUpdate.productLimit = this.elapsedTime(this.editedDates.productLimit)
      this.editedLastUpdate.customers = this.elapsedTime(this.editedDates.customers)
      
      this.editedLastUpdate.topProducts = this.elapsedTime(this.editedDates.topProducts)
      this.editedLastUpdate.topCustomers = this.elapsedTime(this.editedDates.topCustomers)
    }, 60000);
  },

	methods: {
    async listOfBranchOffices(){
      let me=this;
			var branchOfficesArray=[];
			await me.getObjectResponse('api/BranchOffice/Select', me.baseRequest).then(data => {
				if (data !== undefined) {
					branchOfficesArray = data.branchOffices; 
					branchOfficesArray.map(function(x){
            me.branchOffices.push({text:x.visualName, value:x.id});
            if (x.main) {
							me.monthlyPurchasesBranchOffice = x.visualName;
						}
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
		},

    elapsedTime(lastDate){
      if (!lastDate) {
        return 'Actualizado recientemente'
      }

      let result='Actualizado '
      var today = new Date(); 
      // var Christmas = new Date("12-25-2012"); 
      var lastUpdate = new Date(lastDate); 
      // var diffMs = (lastUpdate - today); // milliseconds between now & Christmas 
      var diffMs = (today - lastUpdate); // milliseconds between now & Christmas 
      var diffDays = Math.floor(diffMs/86400000); // days 
      var diffHrs = Math.floor((diffMs % 86400000)/3600000); // hours 
      var diffMins = Math.round(((diffMs % 86400000) % 3600000)/60000); // minutes 

      if (diffDays <= 0 && diffHrs <= 0 && diffMins <= 0) {
        result = result.concat('recientemente')
      } else {
        result = result.concat('hace ')
        if (diffDays > 0) {
          result = result.concat(`${diffDays}`)
          result = result.concat(diffDays === 1 ? ' día ' : ' días ')
        }

        if (diffHrs > 0) {
          result = result.concat(`${diffHrs}`)
          result = result.concat(diffHrs === 1 ? ' hr. ' : ' hrs. ')
        }

        if (diffMins > 0) {
          result = result.concat(`${diffMins}`)
          result = result.concat(diffMins === 1 ? ' minuto ' : ' minutos ')
        }
      }

      return result
    },

    recentlyUpdated(value){
      let me = this
      const text = 'Actualizado recientemente'
      switch (value) {
        case 'monthlyPurchases':
          me.editedDates.monthlyPurchases = new Date()
          me.editedLastUpdate.monthlyPurchases = text
          break;
        case 'dailySales':
          me.editedDates.dailySales = new Date()
          me.editedLastUpdate.dailySales = text
          break;
        case 'monthlySales':
          me.editedDates.monthlySales = new Date()
          me.editedLastUpdate.monthlySales = text
          break;
        
        case 'branchOfficeLimit':
          me.editedDates.branchOfficeLimit = new Date()
          me.editedLastUpdate.branchOfficeLimit = text
          break;
        case 'userLimit':
          me.editedDates.userLimit = new Date()
          me.editedLastUpdate.userLimit = text
          break;
        case 'productLimit':
          me.editedDates.productLimit = new Date()
          me.editedLastUpdate.productLimit = text
          break;
        case 'customers':
          me.editedDates.customers = new Date()
          me.editedLastUpdate.customers = text
          break;

        case 'topProducts':
          me.editedDates.topProducts = new Date()
          me.editedLastUpdate.topProducts = text
          break;
        case 'topCustomers':
          me.editedDates.topCustomers = new Date()
          me.editedLastUpdate.topCustomers = text
          break;
      
        default:
          break;
      }
    },

    async getControlPanel(){
      let me=this;
      try {
        me.loadingControlPanel(true)
        await me.getObjectResponse('api/Company/GetOwnControlData', me.baseRequest).then(data => {
          if (data !== undefined) {
            me.editedControlPanel = Object.assign({}, data.controlPanel)

            me.$store.commit("SET_COMPANY_LIMITS", 
              {
                branchOfficeLimit: me.editedControlPanel.branchOfficeLimit,
                userLimit: me.editedControlPanel.userLimit,
                productLimit: me.editedControlPanel.productLimit
              }
            );
            
            me.recentlyUpdated('branchOfficeLimit')
            me.recentlyUpdated('userLimit')
            me.recentlyUpdated('productLimit')
            me.recentlyUpdated('customers')
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.loadingControlPanel(false)
      }
    },
    
    loadingControlPanel(value) {
      this.branchOfficeLoading = value
      this.userLoading = value
      this.productLoading = value
      this.customerLoading = value
    },


		async getMonthlyPurchases(){
      let me=this;
      try {
        me.monthlyPurchasesLoading = true
        me.monthlyPurchasesLoaded = false
        var purchasesArray=[];
        await me.getObjectResponse('api/CompanyPurchase/GetMonthlyValues', me.baseRequest).then(data => {
          if (data !== undefined) {
            purchasesArray = data.purchases;
            me.highMonthlyPurchase = data.maxValue
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
        me.loadMonthlyPurchasesChart(purchasesArray)
      } catch (error) {
        me.catchError(error)
      } finally {
        me.monthlyPurchasesLoading = false
      }
		},

		loadMonthlyPurchasesChart(purchasesArray){
			let me=this;
			me.monthlyPurchasesLabels = []
			me.monthlyPurchasesValues = []
			purchasesArray.map(function(x){
				me.monthlyPurchasesLabels.push(me.getNameOfMonth(parseInt(x.label, 10), false))
				me.monthlyPurchasesValues.push(x.value)
      })

      if (me.monthlyPurchasesLabels.length && me.monthlyPurchasesValues.length) {
        me.monthlyPurchasesChart.data.labels = me.monthlyPurchasesLabels
        me.monthlyPurchasesChart.data.datasets[0].data = me.monthlyPurchasesValues
        // me.monthlyPurchasesChart.options.high = me.highMonthlyPurchase 
      } else {
        me.monthlyPurchasesChart.data.labels = me.defaultMonthlyPurchasesLabels
        me.monthlyPurchasesChart.data.datasets[0].data = me.defaultMonthlyPurchasesValues
      }

      me.monthlyPurchasesLoaded = true
      me.recentlyUpdated('monthlyPurchases')
		},

		async getDailySales(){
      let me=this;
      try {
        me.dailySalesLoading = true
        me.dailySalesLoaded = false
        var salesArray=[];
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'branchOfficeId': me.dailySalesBranchOfficeId
        };
        await me.getObjectResponse('api/CompanySale/GetDailyValues', request).then(data => {
          if (data !== undefined) {
            salesArray = data.sales;
            me.highDailySale = data.maxValue
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });

        me.loadDailySalesChart(salesArray)
      } catch (error) {
        me.catchError(error)
      } finally {
        me.dailySalesLoading = false
      }
		},

		loadDailySalesChart(salesArray){
			let me=this;
			me.dailySalesLabels = []
			me.dailySalesValues = []
			salesArray.map(function(x){
				me.dailySalesLabels.push(me.getNameOfDay(parseInt(x.label, 10), false))
				me.dailySalesValues.push(x.value)
      })
      
      if (me.dailySalesLabels.length && me.dailySalesValues.length) {
        me.dailySalesChart.data.labels = me.dailySalesLabels
        me.dailySalesChart.data.datasets[0].data = me.dailySalesValues
        // me.dailySalesChart.options.high = me.highDailySale
      } else {
        me.dailySalesChart.data.labels = me.defaultDailySalesLabels
        me.dailySalesChart.data.datasets[0].data = me.defaultDailySalesValues
      }

      me.dailySalesLoaded = true
      me.recentlyUpdated('dailySales')
		},

		async getMonthlySales(){
      let me=this;
      try {
        me.monthlySalesLoading = true
        me.monthlySalesLoaded = false
        var salesArray=[];
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'branchOfficeId': me.monthlySalesBranchOfficeId
        };
        await me.getObjectResponse('api/CompanySale/GetMonthlyValues', request).then(data => {
          if (data !== undefined) {
            salesArray = data.sales;
            me.highMonthlySale = data.maxValue
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });

        me.loadMonthlySalesChart(salesArray)
      } catch (error) {
        me.catchError(error)
      } finally {
        me.monthlySalesLoading = false
      }
		},

		loadMonthlySalesChart(salesArray){
			let me=this;
			me.monthlySalesLabels = []
			me.monthlySalesValues = []
			salesArray.map(function(x){
				me.monthlySalesLabels.push(me.getNameOfMonth(parseInt(x.label, 10), false))
				me.monthlySalesValues.push(x.value)
			})
			
      if (me.monthlySalesLabels.length && me.monthlySalesValues.length) {
        me.monthlySalesChart.data.labels = me.monthlySalesLabels
        me.monthlySalesChart.data.datasets[0].data = me.monthlySalesValues
        // me.monthlySalesChart.options.high = me.highMonthlySale
      } else {
        me.monthlySalesChart.data.labels = me.defaultMonthlySalesLabels
        me.monthlySalesChart.data.datasets[0].data = me.defaultMonthlySalesValues
      }

      me.monthlySalesLoaded = true
      me.recentlyUpdated('monthlySales')
		},


		async getMainProductsSold(){
      let me=this;
      try {
        me.products=[];
        me.topProductsLoading = true
        await me.getObjectResponse('api/ProductInventory/GetMainProductsSold', me.baseRequest).then(data => {
          if (data !== undefined) {
            me.products = data.products; 
            me.recentlyUpdated('topProducts')
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.topProductsLoading = false
      }
		},

		async getUsualCustomers(){
      let me=this;
      try {
        me.customers=[];
        me.topCustomersLoading = true
        await me.getObjectResponse('api/CompanySale/GetUsualCustomers', me.baseRequest).then(data => {
          if (data !== undefined) {
            me.customers = data.customers; 
            me.recentlyUpdated('topCustomers')
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.topCustomersLoading = false
      }
		},
	},
}
</script>